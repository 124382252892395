<template>
  <div :class="classNames" class="bitts-alert">
    <div class="flex flex-col">
      <div class="bitts-alert__left-banner" />
      <div class="flex items-center h-full">
        <div class="self-start flex items-start pt-4">
          <div
            v-if="slots.icon"
            class="mr-8 leading-4 inline-flex items-center"
            aria-hidden="true"
          >
            <slot name="icon" />
          </div>
          <FontAwesomeIcon
            :icon="iconType"
            :style="{ height: '16px', width: '16px', color: 'currentColor' }"
            :title="accessibilityAttributeText('alert')"
            stroke="text-success-accent"
            class="bitts-alert__icon"
            :class="iconColor"
            aria-hidden="true"
          />
        </div>
        <div class="flex flex-col">
          <span class="invisible absolute">
            {{ accessibilityAttributeText('message') }}
          </span>
          <div class="flex" v-if="message">
            <span class="bitts-alert__message" v-html="message" />
            <slot name="message-link" />
          </div>
          <div
            v-if="description"
            class="bitts-alert__description"
            data-testid="alert-description"
          >
            <span v-html="description" />
            <slot name="link" />
          </div>
          <div v-else class="bitts-alert__body">
            <slot name="body" />
          </div>
        </div>
      </div>
      <slot name="bitts-alert-extra-content" />
    </div>
    <slot name="button" />
  </div>
</template>

<script lang="ts">
import { capitalize } from 'lodash';
import { computed, useSlots } from 'vue';

import { BittsColor } from '../types';
export type BittsAlertColor = BittsColor;
export interface BittsAlertProps {
  message?: string;
  description?: string;
  color?: BittsAlertColor;
}
</script>
<script setup lang="ts">
const props = withDefaults(defineProps<BittsAlertProps>(), {
  color: 'success',
  description: '',
  message: '',
});

const slots = useSlots();

const classNames = computed(() => {
  return [
    `bitts-alert-${props.color}`,
    `${
      props.description || slots.body
        ? 'bitts-alert-card'
        : 'bitts-alert-inline'
    }`,
  ];
});

const iconColor = computed(
  () =>
    ({
      success: 'text-success-accent',
      info: 'text-info-accent',
      error: 'text-danger-accent',
      upgrade: 'text-upsell-text',
      warning: 'text-warning-accent',
    })[props.color],
);

const iconType = computed(
  () =>
    ({
      success: ['far', 'check-circle'],
      info: ['far', 'info-circle'],
      error: ['far', 'exclamation-circle'],
      upgrade: ['far', 'stars'],
      warning: ['far', 'exclamation-triangle'],
    })[props.color],
);

function accessibilityAttributeText(text: string) {
  return `${capitalize(props.color)} ${text}`;
}
</script>

<style lang="pcss" scoped>
.bitts-alert {
  @apply text-base rounded-bts-sm py-8 pl-12 pr-16 relative;

  &-inline {
    @apply flex flex-row items-center;
    min-height: 32px;
  }

  &-card {
    & .bitts-alert__message {
      @apply font-bold;
    }
  }

  .bitts-alert__icon {
    @apply mr-8;
  }

  /* error */
  &-error {
    @apply bg-danger-background-weak;
    & .bitts-alert__description {
      @apply text-danger-text;
    }
    & .bitts-alert__message,
    .bitts-alert__body {
      @apply text-danger-text-strong;
    }
    .bitts-alert__left-banner {
      @apply bg-danger-accent;
    }
  }

  &-error:not(.bitts-alert-card) {
    & .bitts-alert__message {
      @apply text-danger-text;
    }
  }

  /* info */
  &-info {
    @apply bg-info-background-weak;
    & .bitts-alert__description {
      @apply text-info-text;
    }
    & .bitts-alert__message,
    .bitts-alert__body {
      @apply text-info-text-strong;
    }
    .bitts-alert__left-banner {
      @apply bg-info-accent;
    }
  }

  &-info:not(.bitts-alert-card) {
    & .bitts-alert__message {
      @apply text-info-text;
    }
  }

  /* success */
  &-success {
    @apply bg-success-background-weak;
    & .bitts-alert__description {
      @apply text-success-text;
    }
    & .bitts-alert__message,
    .bitts-alert__body {
      @apply text-success-text-strong;
    }
    .bitts-alert__left-banner {
      @apply bg-success-background-medium;
    }
  }

  &-success:not(.bitts-alert-card) {
    & .bitts-alert__message {
      @apply text-success-text;
    }
  }

  /* warning */
  &-warning {
    @apply bg-warning-background-weak;
    & .bitts-alert__description {
      @apply text-warning-text;
    }
    & .bitts-alert__message,
    .bitts-alert__body {
      @apply text-warning-text-strong;
    }
    .bitts-alert__left-banner {
      @apply bg-warning-accent;
    }
  }

  &-warning:not(.bitts-alert-card) {
    & .bitts-alert__message {
      @apply text-warning-text;
    }
  }

  /* upgrade */
  &-upgrade {
    @apply bg-upsell-background-weak;
    & .bitts-alert__description {
      @apply text-upsell-text;
    }
    & .bitts-alert__message,
    .bitts-alert__body {
      @apply text-upsell-text-strong;
    }
    .bitts-alert__left-banner {
      @apply bg-upsell-background-medium;
    }
  }

  .bitts-alert__description,
  .bitts-alert__body {
    @apply mt-2;
  }

  .bitts-alert__left-banner {
    @apply absolute left-0 top-0 h-full w-2 rounded-l-4;
  }
}
</style>
